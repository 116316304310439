import React from 'react';
import * as XLSX from 'xlsx';
import moment from "moment";

class Utils {
    static dataToExel(header, body) {

        const data = [
            header,
            ...body.map(c => Object.values(c))
        ];
        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'data.xlsx');
    }
    static sipExel(body,data) {
        let filtered = data.filter((b,_) => body.includes(b.buddy))
        let header = ['Notes','Username',"Password"]
        const book = [
            header,
            ...filtered.map(c =>[c.notes,c.buddy,c.sip_buddy.secret])
        ];
        const ws = XLSX.utils.aoa_to_sheet(book);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'sip_info.xlsx');
    }

    static copy(setCopied){
        {
            let table = document.getElementById('dataBody');
            if (navigator.clipboard) {
                setCopied(true)
                let text = table.innerText.trim();
                navigator.clipboard.writeText(text).catch(function () {
                });
                setTimeout(() => {
                    setCopied(false)
                }, 1000)
            }
        }
    }

    static secToHours(durationInSeconds) {
        // const duration = moment.duration(durationInSeconds, 'seconds');
        // const hours = Math.floor(duration.asHours());
        // const minutes = duration.minutes();
        // const seconds = duration.seconds();
        let hours = Math.floor(durationInSeconds / 3600);
        let minutes = Math.floor((durationInSeconds % 3600) / 60);
        let secs = durationInSeconds % 3600 % 60;
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (secs < 10) {
            secs = "0" + secs;
        }
        return `${hours}:${minutes}:${secs}`;

    }

    static getCount(arr, key) {
        let count = 0;
        for (const obj of arr) {
            if (key in obj) {
                count += obj[key];
            }
        }
        return count;
    }

    static convertHMS(value) {
        const sec = parseInt(value, 10);
        let hours = Math.floor(sec / 3600);
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60);
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        return hours + ':' + minutes + ':' + seconds;
    }

    static formatDate(d) {
        const date = new Date(d); // Current date and time

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    static getRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    static newFormat(d) {
        return new Date(d).toISOString().replace('T', ' ').replace('Z',' ').replace('.000','')
    }
}

export default Utils;
