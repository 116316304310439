import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import BitrixEnter from "../components/BitrixEnter";
import {useSelector} from "react-redux";
import Api from "../Api";
import {useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import axios from "axios";
import qs from "query-string";
import zoro from '../assets/img/crm-logo.avif'
import monday from '../assets/img/monday.png'
import {toast} from "react-toastify";
import AmoEnter from "../components/AmoEnter";
import img from '../assets/img/LogoLightCloud.png'
import crm_img from '../assets/img/crm.png'
import {crm} from "../translate";
import {faArrowRightLong} from "@fortawesome/free-solid-svg-icons";
const {REACT_APP_SERVER} = process.env;
const client_id = REACT_APP_SERVER === 'ATS' ? '1000.OFHJBBAF54EL01AM5NUJXSTSQR78KL':'1000.ICNSLEJ412ROAB4XCH4XAOFWIMGF6D';
const URI = REACT_APP_SERVER === 'ATS'?'https://account.ats.am':'https://ucom-acc.ats.am';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PipeDriveEnter from "../components/PipeDriveEnter";
import MondayEnter from "../components/MondayEnter";

const redirect_uri = `${URI}/crm_integration`;
function CrmIntegration(props) {
    const [currentCrm,setCurrentCrm] = useState('')
    const [isInstalled,setIsInstalled] = useState({bitrix:null,amo:null,pipedrive:null})
    const [isInstalledZoho,setIsInstalledZoho] = useState(false)
    const userData = useSelector((state) => state.users.userData)
    const navigate = useNavigate()
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [crms, setCrm] = useState([])
    useEffect(() => {
        crms.map(c => {
            if(c.name){
                setIsInstalled((prev) => ({...prev,[c.name]:userData[c.name]?.member || userData[c.name]?.member_id}))
            }
        })
        setIsInstalled((prev) => ({...prev,monday: userData.monday?.member_id}))
    },[userData,crms])
    const handleZoho = useCallback(async () => {
        window.location.href = `https://accounts.zoho.com/oauth/v2/auth?response_type=code&client_id=${client_id}&scope=ZohoCRM.modules.ALL,ZohoCRM.users.ALL&redirect_uri=${redirect_uri}&access_type=offline&prompt=consent`
    }, [client_id,redirect_uri])
    const handleDelete = useCallback(async (type) => {
        try {
            if(type === 'bitrix'){
                await Api.bitrix_delete()
            }else if(type === 'amo'){
                await Api.amoDelete()
            }else if(type === 'pipedrive'){
                await Api.pipedrive_delete()
            }else if(type === 'zoho'){
                await Api.zohoDelete()
                setIsInstalledZoho(false)
            }else if(type === 'monday'){
                await Api.monday_delete()
            }
            setIsInstalled((prev) => ({...prev,[type]:0}))

        }catch (e) {
            console.log(e)
        }
    }, [])


    useEffect(() => {
        if(location.hash === '#application_not_found'){
            setCurrentCrm('bitrix')
        }
    }, [location.hash])
    useEffect(() => {
        if(currentCrm === 'amo'){
            // window.location.href = `https://www.amocrm.ru/oauth?client_id=${amo_client}&state=amo_code&mode=popup`
        }
    }, [currentCrm])
    useEffect(() => {
        (async () => {
            try{
                if(query.code && query.state!=='amo_code'){
                    const parsedUrl = new URL(query['accounts-server']);
                    const hostname = parsedUrl.hostname;
                    const region = hostname.split('.').pop();
                    await Api.zohoCode(query.code,region)
                    setIsInstalledZoho(true)
                    navigate('/crm_integration')
                }else if(query.monday_domain){
                    await Api.monday_domain(query.monday_domain)
                    navigate('/crm_integration/monday')

                }
            }catch (e) {
                toast.error(e.response.data.message)
            }
        })()
    }, [location.search])
    useEffect(() => {
        (async () => {
            const {data} = await axios.get(`https://ats.am/api/crm_integration?lang=${localStorage.getItem('atsLang')}`)
            setCrm(data.crm)
        })()
    },[])
    return (
        <Wrapper>
            <Helmet>
                <title>CRM integration</title>
            </Helmet>

            <div className="d-flex flex-wrap">
                {crms?.map(c => {
                    if(c.name){
                        return (<div key={c.id} className="crmBlock" style={{border: "1px solid rgb(204, 204, 204)", padding: "10px"}}>
                                <div>
                                    <div className=" blog-item-img d-flex flex-row align-items-center justify-content-center" style={{height: 140}}>
                                        <img
                                            src={"https://ats.am"+c.image}
                                            alt="" className="img-responsive" style={{width: "75%", height: "75%", objectFit: "contain"}}/>
                                    </div>
                                </div>
                                <div>

                                    <div className="blog-item-content"><h4 style={{marginBottom:25}}>{c.crm_integration_translate[0].title}</h4>
                                        <p className="text-container" style={{marginBottom:25, minHeight:110, }}>{c.crm_integration_translate[0].desc}</p>
                                        {!isInstalled[c.name]?<div style={{borderRadius: 5}} className="addBtnBig" onClick={() => setCurrentCrm(c.name)}>
                                            Add
                                        </div>:<div className="d-flex justify-content-between">
                                            <div style={{borderRadius: 5}} className="deleteBtn addBtnBig" onClick={() => handleDelete(c.name)}>
                                                Delete
                                            </div>
                                            <div style={{borderRadius: 5}} className="addBtnBig" onClick={()=> navigate(`/crm_integration/${c.name}`)}>
                                                View
                                            </div>
                                        </div>}
                                    </div>


                                </div>
                            </div>
                        )
                    }
                })}
                <div className="crmBlock" style={{border: "1px solid rgb(204, 204, 204)", padding: "10px"}}>
                    <div>
                        <div className=" blog-item-img d-flex flex-row align-items-center justify-content-center" style={{height: 140}}>
                            <img
                                src={zoro}
                                alt="" className="img-responsive" style={{width: "75%", height: "75%", objectFit: "contain"}}/>
                        </div>
                    </div>
                    <div>

                        <div className="blog-item-content"><h4 style={{marginBottom:25}}>ZOHO CRM</h4>
                            <p style={{marginBottom:25, minHeight:110}}>Zoho CRM is an online Sales CRM software to help manage your customer operations in one CRM platform. Trusted by over 100+ million users worldwide!</p>
                            {!isInstalledZoho?<div style={{borderRadius: 5}} className="addBtnBig" onClick={handleZoho}>
                                Add
                            </div>:<div className="d-flex justify-content-between">
                                <div style={{borderRadius: 5}} className="deleteBtn addBtnBig" onClick={() => handleDelete('zoho')}>
                                    Delete
                                </div>
                                <div style={{borderRadius: 5}} className="addBtnBig" onClick={()=> navigate(`/crm_integration/zoho`)}>
                                    View
                                </div>
                            </div>}
                        </div>


                    </div>
                </div>
                <div className="crmBlock" style={{border: "1px solid rgb(204, 204, 204)", padding: "10px"}}>
                    <div>
                        <div className=" blog-item-img d-flex flex-row align-items-center justify-content-center" style={{height: 140}}>
                            <img
                                src={monday}
                                alt="" className="img-responsive" style={{width: "75%", height: "75%", objectFit: "contain"}}/>
                        </div>
                    </div>
                    <div>

                        <div className="blog-item-content"><h4 style={{marginBottom:25}}>Monday CRM</h4>
                            <p style={{marginBottom:25, minHeight:110}}>Collect more leads and enhance their experience with monday sales CRM. Get full visibility into your sales funnel and automate repetitive work.</p>
                            {!isInstalled['monday']?<div style={{borderRadius: 5}} className="addBtnBig" onClick={() => setCurrentCrm('monday')}>
                                Add
                            </div>:<div className="d-flex justify-content-between">
                                <div style={{borderRadius: 5}} className="deleteBtn addBtnBig" onClick={() => handleDelete('monday')}>
                                    Delete
                                </div>
                                <div style={{borderRadius: 5}} className="addBtnBig" onClick={()=> navigate(`/crm_integration/monday`)}>
                                    View
                                </div>
                            </div>}
                        </div>


                    </div>
                </div>
                <div className="crmBlock" style={{border: "1px solid rgb(204, 204, 204)", padding: "10px"}}>
                    <div>
                        <div className=" blog-item-img d-flex flex-row align-items-center justify-content-center" style={{height: 140}}>
                            <img
                                style={{width:90}}
                                src={img}
                                alt="" className="img-responsive"/>
                            <FontAwesomeIcon style={{fontSize: 40, margin: 15}} icon={faArrowRightLong} />
                            <img
                                style={{width:90}}
                                src={crm_img}
                                alt="" className="img-responsive"/>

                        </div>
                    </div>
                    <div>

                        <div className="blog-item-content"><h4 style={{marginBottom:25}}>{crm.my_crm[+localStorage.getItem('atsLang')  || 1]}</h4>
                            <p style={{marginBottom:25, minHeight:110}}>{crm.my_crm_desc[+localStorage.getItem('atsLang')  || 1]}</p>
                            <div style={{borderRadius: 5}} className="addBtnBig" onClick={() => navigate('/crm_integration/custom')}>
                                View
                            </div>
                        </div>


                    </div>
                </div>
                <BitrixEnter open={currentCrm === 'bitrix'} setOpen={setCurrentCrm} />
                <PipeDriveEnter setIsInstalled={setIsInstalled} open={currentCrm === 'pipedrive' || window.location.hash === "#application_installed"} setOpen={setCurrentCrm} />
                <AmoEnter open={currentCrm === 'amo'} setOpen={setCurrentCrm} />
                <MondayEnter open={currentCrm === 'monday'} setOpen={setCurrentCrm} />
            </div>
        </Wrapper>
    );
}

export default CrmIntegration;
