import React from 'react';
import {global} from "../translate";
import {sortableKeyboardCoordinates, useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandPointer} from "@fortawesome/free-solid-svg-icons";

function ShortLine({s, handleDelete,sip}) {
    const {attributes, listeners, setNodeRef, transform, transition} =
        useSortable({id: s.id});
    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
    };
    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 8,
        },
    });
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            delay: 200,
            tolerance: 6,
        },
    });
    const sensors = useSensors(
        mouseSensor,
        touchSensor,
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    return (

        <div style={{...style, width: 'fit-content', margin: '10px 0'}} ref={setNodeRef} {...sensors} {...attributes}
             className="d-flex flex-row text-center">
            <div style={{width: 170,textAlign:'left'}}>
                <span style={{float:'left',width: 40, display: 'inline-block'}} {...listeners}><FontAwesomeIcon style={{fontSize:20}} icon={faHandPointer} /></span>
                <span style={{userSelect: 'text', pointerEvents: 'auto',marginLeft:10}}>
                    {s.num} {sip.find(k => k.buddy === s.num)?.issip ? '(S)':''} {s.num.includes('(') ? '()' : `(${s.notes})`}
                </span>
            </div>
            <div {...listeners} style={{width: 150}}>
                                    <span style={{width: '40%', height: 35, fontSize: 14, display: 'inline-block'}}>
                                       {s.weekStart}
                                   </span>
                <span>-</span>
                <span style={{width: '40%', height: 35, fontSize: 14, display: 'inline-block'}}>
                                        {s.weekEnd}
                                    </span>
            </div>
            <div {...listeners} style={{width: 150}}>
                                    <span style={{width: '40%', height: 35, fontSize: 14}}>
                                        {s.timeStart}
                                    </span>
                <span>-</span>
                <span style={{width: '40%', height: 35, fontSize: 14}}>
                                        {s.timeEnd}
                                    </span>
            </div>
            <div style={{width: 150}} className="d-flex justify-content-center">
                <button onClick={() => handleDelete(s.id)} className="deleteBtn"
                        style={{background: 'red'}}>
                    {global.delete[+localStorage.getItem('atsLang') || 1]}
                </button>
            </div>
        </div>
    );
}

export default ShortLine;
