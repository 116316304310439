import React, {useCallback, useEffect} from 'react';
import Wrapper from "../components/Wrapper";
import {
    ReactFlow,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';

import Paper from "@mui/material/Paper";
import Api from "../Api";
import CustomNode from "../components/CustomNode";
import Utils from "../Utils";


const initialNodes = [
    { id: '1',
        type: "custom",
        position: { x: 100, y: 0 }, data: { sourceType:'target',label: '377777',type:'SIP' } },
    { id: '2',
        type: "custom",
        position: { x: 100, y: 100 }, data: { sourceType:'source',label: '37495067515',type:'DID' } },
];
const nodeTypes = {
    custom: CustomNode,
};
let sourceColorMap = {
    '55': 'blue',
    '2': 'green',
    '3': 'red',
};

const getColorForSource = (source) => sourceColorMap[source] || 'gray';
const initialEdges = [

];
function DragConnect(props) {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const onConnect = useCallback(
        (params) => {
            console.log(params)
            let {sourceHandle,targetHandle} = params;
            if(sourceHandle.split('_')[0] === targetHandle.split('_')[0]){
                return
            }
            setEdges((eds) => addEdge(params, eds))
        },
        [setEdges],
    );
    const coloredEdges = edges.map((edge) => ({
        ...edge,
        style: { stroke: getColorForSource(edge.source), strokeWidth: 2 },
    }));
    useEffect(() => {
        (async () => {
            const {data} = await Api.drag()
            let color = 'white'

            let fetchedNodesShort = data.data.short.map((s,_) => {
                color = Utils.getRandomColor()
                sourceColorMap = {...sourceColorMap,[s.virtual.toString()]:color}
                return ({
                    id: s.virtual.toString(),
                    type: "custom",
                    position: {x: 100, y: _ * 52},
                    data: {color,label: `${s.virtual} (${s.name})`, type: 'Short'}
                })
            })
            let fetchedNodesSip = data.data.sip.map((s,_) => ({id:s.buddy,type: "custom", position: { x: 550, y: _ * 52 },data:{label:`${s.buddy} ${s.issip?'(S)':''}  ${s.notes? '- '+ s.notes:''}`,type:'SIP'}}))
            let fetchedEdgeSip = [];

            let fetchedNodesDid = data.data.did.map((s,_) => ({id:s.pref+s.did,type: "custom", position: { x: 850, y: _ * 52 },data:{label:`${s.pref+s.did} ${s.notes}`,type:'DID'}}))
            data.data.short.map(s => {
                if(s.child.length){
                   s.child.map(c =>  fetchedEdgeSip.push(
                       {id: c.id,
                           source: s.virtual.toString(),
                           sourceHandle: 'Short_source_' + s.virtual.toString() || 'source', // Default handle
                           target: c.buddy,
                           targetHandle: 'SIP_target_' + c.buddy || 'target', }))
                }
            })
            setNodes([...fetchedNodesSip,...fetchedNodesShort,...fetchedNodesDid])
            setEdges([...fetchedEdgeSip])
        }) ()
    },[])
    return (
        <Wrapper>
            <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                <div style={{ width: '100%', height: '100vh' }}>
                    <ReactFlow
                        nodes={nodes}
                        nodeTypes={nodeTypes}
                        edges={coloredEdges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                    >
                        <Controls />
                        <MiniMap />
                        <Background variant="dots" gap={12} size={1} />
                    </ReactFlow>
                </div>
            </Paper>
        </Wrapper>
    );
}

export default DragConnect;
