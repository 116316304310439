import React from 'react';
import {Handle, Position} from "@xyflow/react";

function CustomNode(prop) {
    const {id,data} = prop;
    return (
        <>
           <Handle position={Position.Right} type='source' id={data.type+ '_source_' + id}/>
           <div className="custom_node" style={data.color?{borderColor:data.color}:{}}>
               <div>{data.type}</div>
               <div>{data.label}</div>
           </div>
            <Handle type="target" position={Position.Left} id={data.type + '_target_'  + id} />
        </>
    );
}

export default CustomNode;
