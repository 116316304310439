import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import TableComponent from "../components/TableComponent";
import {did, forward, global, historyHeader, included, input, menu, short, sip} from "../translate";
import {Helmet} from "react-helmet";
import Api from "../Api";
import Cookies from 'js-cookie'
import {Link, useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";
const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS'?'#0973b9':'#7ebc0a'
function Home() {
    const [search, setSearch] = useState('')
    const [all, setAll] = useState({})
    const navigate = useNavigate()
    useEffect(() => {
        if (!Cookies.get('viewer')){
            navigate('/sip_number')
        }
    },[])
    const handleSubmit = useCallback(async (ev) => {
        try {
            ev.preventDefault()
            const {data} = await Api.globalSearch(search)
            setAll(data)
        }catch (e) {

        }
    }, [search])
    return (
       <>
           <Helmet>
               <title>
                   {menu.main[+localStorage.getItem('atsLang')  || 1 ]}
               </title>
           </Helmet>
           <Wrapper>
               <form onSubmit={handleSubmit} style={{minHeight:all.status?'50px':'100vh',width:'100%',padding:15}}>
                   <input value={search} onChange={(ev) => setSearch(ev.target.value)} placeholder={input.search[+localStorage.getItem('atsLang')  || 1 ]} className="home_input"/>
               </form>

               {all?.sipResult?.length? <Paper sx={{minWidth: 'fit-content', marginLeft:2,fontSize: 10}}>
                   <TableContainer
                   >
                       <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                               <TableRow className="sip_header">
                                   <TableCell sx={{fontSize: 20,}} align="left" colSpan={3}>
                                       {menu.sip[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell sx={{fontSize: 15, padding: '0 35px',height:50}} align="right" colSpan={6}/>
                               </TableRow>
                               <TableRow className="sip_num">
                                   <TableCell
                                       align='center'
                                   />
                                   <TableCell align='center'>
                                       {sip.phone[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell align='center'>
                                       {sip.note[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   {window.innerWidth < 400 ? null : <TableCell
                                       align='center'
                                   >
                                       {sip.out[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>}
                                   {window.innerWidth < 400 ? null : <TableCell
                                       align='center'
                                   >
                                       {sip.forward[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>}
                                   {window.innerWidth < 400 ? null : <TableCell
                                       align='center'

                                   >
                                       {sip.outgoing[+localStorage.getItem('atsLang')  || 1]}

                                   </TableCell>}
                                   <TableCell
                                       align='center'
                                   >
                                       {sip.rec[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {sip.crm[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                               </TableRow>
                           </TableHead>
                           <TableBody id="dataBody">
                               {all?.sipResult?.length ? all?.sipResult?.map((s, _) => (
                                   <TableRow onClick={() => navigate(`/sip_number?search=${s.buddy}`)} key={_} hover role="checkbox" tabIndex={-1}>
                                       <TableCell align="center">
                                           {s?.ipStat?<CheckCircleIcon style={{color:color}} />:null}
                                       </TableCell>
                                       <TableCell align="center" style={{cursor:'pointer'}} >
                                           <span style={{borderBottom:'1px solid',fontSize:12}}>{s.buddy} {s.issip?'(S)':''}</span>
                                       </TableCell>
                                       <TableCell align="center">
                                           {s.notes}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s.sipdid}
                                       </TableCell>
                                       {window.innerWidth < 400 ? null : <TableCell align="center">
                                           {s?.rnums}
                                       </TableCell>}
                                       {window.innerWidth < 400 ? null : <TableCell align="center">
                                           {s?.codetypeResult?.map(m => <span key={m.id}> {m.later} </span>)}
                                       </TableCell>}
                                       {window.innerWidth < 400 ? null : <TableCell align="center">
                                           {+s.rec ? 'R' + s.rec : ''}
                                       </TableCell>}
                                       <TableCell align="center">
                                           {s?.crm?.length ? 'C' : ''}
                                       </TableCell>
                                   </TableRow>

                               )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                   <TableCell align="center" colSpan={window.innerWidth < 400 ? 6 : 10}>
                                       no data

                                   </TableCell>


                               </TableRow>
                               }

                           </TableBody>
                       </Table>
                   </TableContainer>
               </Paper>:null}
               {all?.redirectResult?.length? <Paper sx={{minWidth: 'fit-content', marginLeft:2,marginTop:2,fontSize: 10}}>
                       <TableContainer
                       >
                           <Table stickyHeader aria-label="sticky table">
                               <TableHead>
                                   <TableRow className="sip_header">
                                       <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                           {forward.forward[+localStorage.getItem('atsLang')  || 1]}
                                       </TableCell>
                                       <TableCell sx={{fontSize: 15,}} align="right" colSpan={6}/>
                                   </TableRow>
                                   <TableRow className="sip_num">
                                       <TableCell
                                           align='center'
                                       />
                                       <TableCell
                                           align='center'
                                       >
                                           {sip.phone[+localStorage.getItem('atsLang')  || 1]}
                                       </TableCell>
                                       <TableCell
                                           align='center'
                                       >
                                           {sip.note[+localStorage.getItem('atsLang')  || 1]}

                                       </TableCell>
                                       <TableCell
                                           align='center'
                                       >
                                           {sip.forward[+localStorage.getItem('atsLang')  || 1]}
                                       </TableCell>
                                   </TableRow>
                               </TableHead>
                               <TableBody>
                                   {all?.redirectResult?.length ? all?.redirectResult?.map(s => (
                                       <TableRow key={s.id} style={{cursor:'pointer'}} hover role="checkbox" tabIndex={-1}>
                                           <TableCell align="center" >
                                               <CheckCircleIcon style={{color: color}}/>
                                           </TableCell>
                                           <TableCell  align="center" onClick={() =>navigate(`/forward?search=${s.input}`)}>
                                               <span style={{borderBottom:'1px solid',fontSize:12}}>{s.input}</span>
                                           </TableCell>
                                           <TableCell align="center" >
                                               {s.notes}
                                           </TableCell>
                                           <TableCell align="center" >
                                               {s.output}

                                           </TableCell>
                                       </TableRow>

                                   )) : <TableRow hover role="checkbox" tabIndex={-1}>

                                       <TableCell align="center" colSpan={5} sx={{fontSize: 13}}>
                                           no data

                                       </TableCell>
                                   </TableRow>
                                   }

                               </TableBody>
                           </Table>
                       </TableContainer>
                   </Paper> :null}
               {all?.shortResult?.length? <Paper sx={{minWidth: 'fit-content', marginLeft:2,marginTop:2,fontSize: 10}}>
                   <TableContainer
                   >
                       <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                               <TableRow className="sip_header">
                                   <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                       {menu.shortC[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell sx={{fontSize: 15,}} align="right" colSpan={6}/>
                               </TableRow>
                               <TableRow className="sip_num">
                                   <TableCell
                                       align='center'
                                   />
                                   <TableCell
                                       align='center'
                                   >
                                       {sip.note[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {sip.phone[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {short.short[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {short.list[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {short.no_answer[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                               </TableRow>
                           </TableHead>
                           <TableBody>
                               {all?.shortResult.length ? all?.shortResult.map(s => (
                                   <TableRow key={s.id} style={{cursor:'pointer'}} hover role="checkbox" tabIndex={-1}>

                                       <TableCell align="center">
                                           {s?.cwaiting?<CheckCircleIcon style={{color:color}} />:<CheckCircleOutlineIcon style={{color:color}} />}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s?.name || ''} {s?.sip[s?.sip?.length - 1]?.notes||''}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s?.sip[s?.sip?.length - 1]?.mobnum?.output||''}
                                       </TableCell>
                                       <TableCell onClick={() => navigate(`/short_numbers?search=${s.virtual}`)} align="center">
                                           <span style={{borderBottom:'1px solid',fontSize:12}}>{s?.virtual}</span>
                                       </TableCell>
                                       <TableCell align="center">
                                           {s?.sip?.length}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s?.noaction}
                                       </TableCell>

                                   </TableRow>

                               )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                   <TableCell align="center" colSpan={9} sx={{fontSize: 13}}>
                                       no data

                                   </TableCell>

                               </TableRow>
                               }
                           </TableBody>
                       </Table>
                   </TableContainer>
                   </Paper> :null}
               {all?.memberPhonesResult?.length? <Paper sx={{minWidth: 'fit-content', marginLeft:2,marginTop:2,fontSize: 10}}>
                   <TableContainer
                   >
                       <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                               <TableRow className="sip_header">
                                   <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                       {menu.included[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell sx={{fontSize: 15, padding: '0 35px', height: 50}} align="right"
                                              colSpan={6}/>
                               </TableRow>
                               <TableRow className="sip_num">
                                   <TableCell
                                       align='center'
                                   >
                                       {sip.phone[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {sip.note[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {sip.out[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {included.access[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {included.mob[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                               </TableRow>
                           </TableHead>
                           <TableBody id="dataBody">
                               {all?.memberPhonesResult ? all?.memberPhonesResult?.map(s => (
                                   <TableRow key={s?.id} hover role="checkbox" tabIndex={-1}>

                                       <TableCell align="center">
                                           {s?.phone}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s?.context}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s?.sipdid}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s?.codetypeResult?.map(m => <span key={m.id}> {m.later} </span>)}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s?.phoneProvider?.name}
                                       </TableCell>
                                   </TableRow>

                               )) : <TableRow hover role="checkbox" tabIndex={-1}>


                                   <TableCell align="center" colSpan={6} sx={{fontSize: 13, width: 150}}>
                                       no data

                                   </TableCell>


                               </TableRow>
                               }

                           </TableBody>
                       </Table>
                   </TableContainer>
                   </Paper> :null}
               {all?.fixResult?.length? <Paper sx={{minWidth: 'fit-content', marginLeft:2,marginTop:2,fontSize: 10}}>
                   <TableContainer
                   >
                       <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                               <TableRow className="sip_header">
                                   <TableCell sx={{fontSize: 20,}} align="left" colSpan={5}>
                                       {did.did[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell sx={{fontSize: 15, padding: '0 35px',}} align="right" colSpan={6}/>
                               </TableRow>
                               <TableRow className="sip_num">
                                   <TableCell
                                       align='center'
                                   />
                                   <TableCell
                                       align='center'
                                   >
                                       {sip.phone[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>

                                   <TableCell
                                       align='center'
                                   >
                                       {sip.forward[+localStorage.getItem('atsLang')  || 1]}

                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {did.line[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {did.used_lines[+localStorage.getItem('atsLang')  || 1]}

                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {did.calc[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>

                                   <TableCell
                                       align='center'
                                   >
                                       {did.active[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {did.date[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>

                               </TableRow>
                           </TableHead>
                           <TableBody>
                               {!all?.fixResult?.length ?

                                   <TableRow hover role="checkbox" tabIndex={-1}>

                                       <TableCell align="center" colSpan={12} sx={{fontSize: 13}}>
                                           Loading...
                                       </TableCell>
                                   </TableRow> :
                                   !all?.fixResult?.length?
                                       <TableRow hover role="checkbox" tabIndex={-1}>

                                           <TableCell align="center" colSpan={12} sx={{fontSize: 13}}>
                                               no data

                                           </TableCell>
                                       </TableRow> : all?.fixResult?.map(s => (
                                           <TableRow style={{cursor:'pointer'}} key={s.pref + s.did} hover role="checkbox" tabIndex={-1}>
                                               <TableCell align="center">
                                                   {s.redirect?.st === 10 || (!s.redirect?.st && s.ord > 0) ?
                                                       <CheckCircleIcon style={{color: color}}/> : null}
                                               </TableCell>
                                               <TableCell onClick={() => navigate(`/did_numbers?search=${s.pref + s.did}`)} align="center">
                                                   <span style={{borderBottom:'1px solid',fontSize:12}}>{s.pref + s.did}</span>
                                               </TableCell>
                                               <TableCell align="center">
                                                   {s.output?.output}
                                               </TableCell>
                                               <TableCell align="center">
                                                   {s.dlines}
                                               </TableCell>
                                               <TableCell align="center">
                                                   {s.totalCount}

                                               </TableCell>
                                               <TableCell align="center">
                                                   <Link
                                                       to={`/history?number=` + s.pref + s.did + 'startTime=' + moment().format('DD-MM-YYYY')}>{s.history}</Link>
                                               </TableCell>
                                               <TableCell align="center">
                                                   {s?.in} - {s?.out}
                                               </TableCell>
                                               <TableCell align="center">
                                                   {s?.inTime?.intime} - {s?.outTime?.outtime}
                                               </TableCell>
                                               <TableCell align="center" sx={{color: 'red'}}>
                                                   {+s.nodline || ''}

                                               </TableCell>
                                           </TableRow>

                                       ))
                               }

                           </TableBody>
                       </Table>
                   </TableContainer>
                   </Paper> :null}
               {all?.routeResult?.length? <Paper sx={{minWidth: 'fit-content', marginLeft:2,marginTop:2,fontSize: 10}}>
                   <TableContainer
                   >
                       <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                               <TableRow className="sip_header">
                                   <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                       {forward.call_rout[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell sx={{fontSize: 15,}} align="right" colSpan={6}/>
                               </TableRow>
                               <TableRow className="sip_num">
                                   <TableCell
                                       align='center'
                                   >
                                       {forward.prefix[+localStorage.getItem('atsLang')  || 1 ]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {sip.out[+localStorage.getItem('atsLang')  || 1 ]}

                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {forward.prefix[+localStorage.getItem('atsLang')  || 1 ]}
                                   </TableCell>
                               </TableRow>
                           </TableHead>
                           <TableBody>
                               {all?.routeResult?.length ? all?.routeResult?.map(s => (
                                   <TableRow style={{cursor:'pointer'}} key={s.id} hover role="checkbox" tabIndex={-1}>
                                       <TableCell align="center" >
                                           {s.input}
                                       </TableCell>
                                       <TableCell onClick={() => navigate(`/rout_phone_number?search=${s.channel}`)} align="center" >
                                           <span style={{borderBottom:'1px solid',fontSize:12}}>{s.channel}</span>
                                       </TableCell>
                                       <TableCell align="center" >
                                           {s.prefix}

                                       </TableCell>
                                   </TableRow>

                               )) : <TableRow hover role="checkbox" tabIndex={-1}>

                                   <TableCell align="center" colSpan={5}>
                                       no data

                                   </TableCell>
                               </TableRow>
                               }

                           </TableBody>
                       </Table>
                   </TableContainer>
                   </Paper> :null}
               {all?.ipResult?.length? <Paper sx={{minWidth: 'fit-content', marginLeft:2,marginTop:2,fontSize: 10}}>
                   <TableContainer
                   >
                       <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                               <TableRow className="sip_header">
                                   <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                       {menu.ip[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell sx={{fontSize: 15,}} align="right" colSpan={6}/>
                               </TableRow>
                               <TableRow className="sip_num">
                                   <TableCell
                                       align='center'
                                   >
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {global.ip[+localStorage.getItem('atsLang')  || 1 ]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {global.date[+localStorage.getItem('atsLang')  || 1 ]}

                                   </TableCell>
                               </TableRow>
                           </TableHead>
                           <TableBody>
                               {all?.ipResult?.length ? all?.ipResult?.map(s => (
                                   <TableRow key={s.id} hover role="checkbox" tabIndex={-1}>
                                       <TableCell align="center" />
                                       <TableCell align="center">
                                           {s.ivr}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s.private_num}
                                       </TableCell>
                                   </TableRow>

                               )) : <TableRow hover role="checkbox" tabIndex={-1}>

                                   <TableCell align="center" colSpan={5} sx={{fontSize: 13}}>
                                       no data

                                   </TableCell>
                               </TableRow>
                               }
                           </TableBody>
                       </Table>
                   </TableContainer>
                   </Paper> :null}
               {all?.blockResult?.length? <Paper sx={{minWidth: 'fit-content', marginLeft:2,marginTop:2,fontSize: 10}}>
                   <TableContainer
                   >
                       <Table stickyHeader aria-label="sticky table">
                           <TableHead>
                               <TableRow className="sip_header">
                                   <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                       {menu.block[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell sx={{fontSize: 15,}} align="right" colSpan={6}/>
                               </TableRow>
                               <TableRow className="sip_num">

                                   <TableCell
                                       align='center'
                                   >
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {historyHeader.phone1[+localStorage.getItem('atsLang')  || 1]}
                                   </TableCell>
                                   <TableCell
                                       align='center'
                                   >
                                       {sip.note[+localStorage.getItem('atsLang')  || 1]}

                                   </TableCell>
                               </TableRow>
                           </TableHead>
                           <TableBody>
                               {all?.blockResult?.length ? all?.blockResult?.map(s => (
                                   <TableRow key={s.id} hover role="checkbox" tabIndex={-1}>

                                       <TableCell align="center">
                                           <CheckCircleIcon style={{color:color}} />
                                       </TableCell>
                                       <TableCell align="center">
                                           {s.phone}
                                       </TableCell>
                                       <TableCell align="center">
                                           {s.notes}
                                       </TableCell>
                                   </TableRow>

                               )) : <TableRow hover role="checkbox" tabIndex={-1}>

                                   <TableCell align="center" colSpan={5} sx={{fontSize: 13}}>
                                       no data

                                   </TableCell>
                               </TableRow>
                               }
                               {/*    );*/}
                               {/*})}*/}
                           </TableBody>
                       </Table>
                   </TableContainer>
                   </Paper> :null}


           </Wrapper>

       </>
    );
}

export default Home;
